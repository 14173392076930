import { template as template_21ab4c94735b4e8daadffcd30d7e6af8 } from "@ember/template-compiler";
const WelcomeHeader = template_21ab4c94735b4e8daadffcd30d7e6af8(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
